import { useAlarmCountCacheUpdater } from './alarm/cache';
import { useSampleCacheUpdater } from './sample/cache';
import type { DataStreamResponse } from './types';
import { isAlarmCountResponse, isSampleResponse } from './types';

export const useCacheUpdater = () => {
  const handleSampleUpdate = useSampleCacheUpdater();
  const handleAlarmCountUpdate = useAlarmCountCacheUpdater();

  return (response: DataStreamResponse) => {
    if (isSampleResponse(response)) {
      handleSampleUpdate(response);
    } else if (isAlarmCountResponse(response)) {
      handleAlarmCountUpdate(response);
    } else {
      console.log(
        'Received unhandled message through WebSocket connection:',
        response
      );
    }
  };
};
