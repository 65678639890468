// Generated by ts-to-zod
import { z } from 'zod';

export const iso8601DurationSchema = z.string();

export const bucketOperationSchema = z.union([
  z.literal('min'),
  z.literal('max'),
  z.literal('average'),
  z.literal('sum'),
  z.literal('disabled'),
  z.literal('min_max_average'),
]);

export const signalNameSchema = z.union([
  z.literal('iCalcLoad'),
  z.literal('dCSS'),
  z.literal('dCSSSetPoint1'),
  z.literal('iCtrlModeASRi'),
  z.literal('iImpactTime'),
  z.literal('dLatitude'),
  z.literal('dLongitude'),
  z.literal('dPressure'),
  z.literal('dOilTemp'),
  z.literal('dPower'),
  z.literal('dPosition'),
  z.literal('dPositionAccuracy'),
  z.literal('dTimeRunningSinceLinersChange'),
  z.literal('iWorkTime'),
  z.string(),
]);

export const derivedSignalNameSchema = z.string();

export const sampleDataSchema = z.object({
  equipmentId: z.string(),
  signalName: z.string(),
  timeStamp: z.string(),
  value: z.number().optional().nullable(),
  minValue: z.number().optional().nullable(),
  maxValue: z.number().optional().nullable(),
  position: z.string().optional(),
});

export const sampleFilterSchema = z.union([
  z.literal('day_first_sample'),
  z.literal('day_last_sample'),
  z.literal('month_first_sample'),
  z.literal('month_last_sample'),
  z.literal('quarter_first_sample'),
  z.literal('quarter_last_sample'),
  z.literal('year_first_sample'),
  z.literal('year_last_sample'),
]);

const histogramBucketDataSchema = z.object({
  from: z.number(),
  to: z.number(),
  time: iso8601DurationSchema,
});

export const signalHistogramDataSchema = z.object({
  equipmentId: z.string(),
  signalName: z.string(),
  buckets: z.array(histogramBucketDataSchema),
  remainderTime: iso8601DurationSchema,
});
