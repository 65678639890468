import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';

const padding = 3;

interface PopperElementProps {
  children: ReactNode;
}

type PopperContainerWidth = 'default' | 'auto' | string;
type PopperContainerMaxHeight = 'default' | string;

interface PopperContainerProps extends PopperElementProps {
  width?: PopperContainerWidth;
  maxHeight?: PopperContainerMaxHeight;
}

function getWidth(width: PopperContainerWidth) {
  if (width === 'auto') {
    return 'auto';
  }
  if (width === 'default') {
    return 'min(500px, 95vw)';
  }
  return width;
}

function getMaxHeight(height: PopperContainerMaxHeight) {
  if (height === 'default') {
    return '100vh';
  }
  return height;
}

export const PopperContainer = ({
  children,
  width = 'default',
  maxHeight = 'default',
}: PopperContainerProps) => (
  <Paper
    elevation={8}
    sx={{
      display: 'flex',
      flexFlow: 'column nowrap',
      width: getWidth(width),
      maxHeight: getMaxHeight(maxHeight),
      overflow: 'hidden',
      borderRadius: 3,
    }}
  >
    {children}
  </Paper>
);

const Header = styled('header', { name: 'Popper', slot: 'Header' })(
  ({ theme }) => ({
    flex: 0,
    padding: theme.spacing(padding),
  })
);

export const PopperHeading = ({ children }: PopperElementProps) => (
  <Header>
    <Typography variant="h3">{children}</Typography>
  </Header>
);

export const PopperContent = styled('div', { name: 'Popper', slot: 'Content' })(
  ({ theme }) => ({
    flex: 1,
    padding: theme.spacing(0, 3),
    overflow: 'auto',
  })
);

export const PopperFooter = styled('footer', {
  name: 'Popper',
  slot: 'Footer',
})(({ theme }) => ({
  flex: 0,
  padding: theme.spacing(2, 3),
}));
