import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAlarmQueryInvalidator } from './useAlarmQueryInvalidator';

interface AlarmCount {
  count: number;
  equipmentId: string;
}

type AlarmCache = Record<string, number>;

// Alarm count is deemed updated if:
//   * number of alarms is changed, and
//   * there exists alarms for the equipment previously.
function isCountUpdated(current: { [p: string]: number }, entry: AlarmCount) {
  return (
    current[entry.equipmentId] !== undefined &&
    current[entry.equipmentId] !== entry.count
  );
}

export const useUpdate = (useInvalidate = false) => {
  const queryClient = useQueryClient();
  const invalidate = useAlarmQueryInvalidator();

  return (newCounts: AlarmCount[]) => {
    queryClient.setQueryData<AlarmCache>(['alarmCount'], (currentCounts) => {
      const result = currentCounts ? { ...currentCounts } : {};
      newCounts.forEach((entry) => {
        if (useInvalidate && isCountUpdated(result, entry)) {
          invalidate(entry.equipmentId);
        }
        result[entry.equipmentId] = entry.count;
      });

      return result;
    });
  };
};

export const useAlarmCount = () => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['alarmCount'],
    queryFn: () => queryClient.getQueryData<AlarmCache>(['alarmCount']) ?? {},
  });
};

export const useUpdateAlarmCount = () => {
  const update = useUpdate();

  return {
    updateAlarmCountForEquipment: (equipmentList: Equipment[]) => {
      const counts = equipmentList
        .filter((eq) => typeof eq.alarmCount === 'number')
        .map((eq) => ({
          equipmentId: eq.id,
          count: eq.alarmCount ?? 0,
        }));

      update(counts);
    },
  };
};
