import { toQueryParams } from '@/api/util';
import { get } from '@/api/v2';
import { isNonEmptyArray } from '@/util/array';
import { ONE_HOUR } from '@/util/time';
import {
  QueryKey,
  QueryStatus,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useAccountSelector } from '../account/AccountProvider';
import criteriaBuilder from '../query/criteriaBuilder';
import { createQueryClause } from '../query/queryClause';
import { useQueryV5 } from '../query/util';

const defaultParams: ListParams<BusinessEntityInclude> = {
  size: 2000,
};

export function useBusinessEntityByIdQuery<T>(
  id: string | number | undefined,
  include?: BusinessEntityInclude,
  queryOptions: UseQueryOptions<T> = {}
) {
  const { account } = useAccountSelector();

  return useQueryV5({
    queryKey: ['businessEntity', id, include],
    queryFn: () =>
      get<T>(
        `entity/${id}${toQueryParams({
          query: getQuery(account?.id),
          include,
        })}`
      ),
    enabled: Boolean(id),
    ...queryOptions,
  });
}

export function useBusinessEntityQuery<T extends BusinessEntityBase[]>(
  criteria?: BusinessEntityCriteria,
  params: ListParams<BusinessEntityInclude> = {},
  queryOptions: UseQueryOptions<T> = {},
  fetchAll: boolean = true
) {
  const { account } = useAccountSelector();

  const p = {
    ...defaultParams,
    query: getQuery(account?.id, criteria),
    ...params,
  };

  const queryKey: QueryKey = isNonEmptyArray(queryOptions.queryKey)
    ? [...queryOptions.queryKey, account]
    : ['businessEntity', p, fetchAll];

  const fetchEntities = async (
    page: number = 0,
    accumulatedResult?: T
  ): Promise<T> => {
    const response = await get<PaginatedHasNextResponse<T>>(
      `entity${toQueryParams({ ...p, page })}`,
      { selector: (json) => json }
    );
    // @ts-ignore
    const result: T = Array.isArray(accumulatedResult)
      ? accumulatedResult.concat(response.data)
      : response.data;

    return response.hasNext && fetchAll
      ? fetchEntities(++page, result)
      : Promise.resolve(result);
  };

  return useQueryV5({
    staleTime: ONE_HOUR,
    queryFn: () => fetchEntities(),
    ...queryOptions,
    queryKey,
  });
}

export function useAccountHierarchyQuery(id: string | undefined) {
  return useQuery(
    ['accountHierarchy', id],
    () =>
      get<BusinessEntitySummary[]>(`entity/${id}/account_hierarchy`, {
        selector: (json) => json,
      }),
    { enabled: Boolean(id) }
  );
}

interface HasPermitResult {
  hasPermit: boolean;
  status: QueryStatus;
}

export function useHasBusinessEntityPermit(
  id: string | undefined,
  permit: Permit
): HasPermitResult {
  const { data: entity, status } =
    useBusinessEntityByIdQuery<BusinessEntityBase>(id, ['permits']);
  const hasPermit = entity?.permits?.includes(permit) ?? false;

  return { hasPermit, status };
}

function getQuery(account?: string, criteria?: Criteria) {
  if (!account && !criteria) {
    return;
  }

  const builder = criteriaBuilder(criteria);

  if (account) {
    builder.ancestor(account);
  }

  return createQueryClause(builder.get());
}
