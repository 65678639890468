import { notEmpty, uniqueValues } from '@/util/array';
import type {
  AlarmCountSubscription,
  DataStreamRequest,
  SampleSubscription,
  Subscription,
} from './types';
import { isAlarmCountSubscription, isSampleSubscription } from './types';

function getSignalNames(subscriptions: SampleSubscription[]): string[] {
  return uniqueValues(
    subscriptions.map((subscription) => subscription.signalNames).flat()
  );
}

function getEquipmentIds(
  subscriptions: (SampleSubscription | AlarmCountSubscription)[]
): string[] {
  return uniqueValues(
    subscriptions
      .map((subscription) => subscription.equipmentIds)
      .flat()
      .filter(notEmpty)
  );
}

function createSampleSubscription(
  subscriptions: Subscription[]
): SampleSubscription | undefined {
  const sampleSubs = subscriptions.filter(isSampleSubscription);
  const signalNames = getSignalNames(sampleSubs);
  const equipmentIds = getEquipmentIds(sampleSubs);

  if (signalNames.length === 0) {
    return undefined;
  }

  return {
    type: 'sample',
    equipmentIds: equipmentIds,
    signalNames,
    includeLatest: true,
  };
}

function createAlarmCountSubscription(
  subscriptions: Subscription[]
): AlarmCountSubscription | undefined {
  if (!subscriptions.some(isAlarmCountSubscription)) {
    return undefined;
  }
  const equipmentIds = getEquipmentIds(
    subscriptions.filter(isAlarmCountSubscription)
  );

  return {
    type: 'alarm:count',
    equipmentIds,
    includeLatest: true,
  };
}

export function createRequest(
  subscriptions: Subscription[]
): DataStreamRequest {
  const sampleSubscription = createSampleSubscription(subscriptions);
  const alarmCountSubscription = createAlarmCountSubscription(subscriptions);

  const requestSubs: Subscription[] = [];
  if (sampleSubscription !== undefined) {
    requestSubs.push(sampleSubscription);
  }
  if (alarmCountSubscription !== undefined) {
    requestSubs.push(alarmCountSubscription);
  }

  return { equipmentIds: [], subscriptions: requestSubs };
}
