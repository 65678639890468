import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

function isAlarmQueryForEquipment(query: any, equipmentId: string) {
  const [domain, opts] = query.queryKey;

  const includesEquipment =
    Array.isArray(opts?.equipment_ids) &&
    opts.equipment_ids.includes(equipmentId);
  const isOpenAlarms =
    opts?.status === 'OPEN' ||
    (Array.isArray(opts?.status) &&
      opts.status.length === 1 &&
      opts.status.includes('OPEN'));

  return domain === 'alarms' && includesEquipment && isOpenAlarms;
}

export const useAlarmQueryInvalidator = () => {
  const queryClient = useQueryClient();

  return useCallback(
    async (equipmentId: string) =>
      queryClient.invalidateQueries({
        predicate: (query) => isAlarmQueryForEquipment(query, equipmentId),
      }),
    [queryClient]
  );
};
