import { useTexts } from '@/feature/lang';
import Button from '@mui/material/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../Dialog';
import Translate from '../../Translate';

export const NewVersionInfo = () => {
  const newVersion = useTexts().component.errorBoundary.newVersion;

  return (
    <Dialog open={true} maxWidth="sm">
      <DialogTitle title={newVersion.header} />
      <DialogContent>
        <Translate text={newVersion.dialogContent} />
      </DialogContent>
      <DialogActions
        right={
          <>
            <Button
              variant="contained"
              onClick={() => window.location.reload()}
            >
              <Translate text={newVersion.reloadButton} />
            </Button>
          </>
        }
      />
    </Dialog>
  );
};
