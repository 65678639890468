import { useUpdate } from '@/feature/alarm/useAlarmCount';
import type { AlarmCountResponse } from '../types';

export const useAlarmCountCacheUpdater = () => {
  const update = useUpdate(true);

  return (response: AlarmCountResponse) => {
    const { equipmentId, alarmCount } = response;
    update([{ equipmentId, count: alarmCount }]);
  };
};
