import { useTranslation } from '../lang';
import { useTranslateEnumValues } from '../lang/hook';
import { EquipmentConnectivity } from './constants';

const useEquipmentPropTexts = () => {
  const { texts, translate } = useTranslation();
  const connectivityValues = texts.fleet.model.connectivity.values;
  const categoryValues = texts.fleet.model.category.values;

  const getConnectivityName = (connectivity: EquipmentConnectivity) => {
    switch (connectivity) {
      case EquipmentConnectivity.CONNECTED:
        return translate(connectivityValues.connected);
      case EquipmentConnectivity.NOT_CONNECTED:
        return translate(connectivityValues.notConnected);
      default:
        return connectivity;
    }
  };

  const getIsConnectedName = (isConnected: boolean) =>
    translate(
      isConnected
        ? connectivityValues.connected
        : connectivityValues.notConnected
    );

  const getCategoryName = useTranslateEnumValues<string>(categoryValues);

  return { getConnectivityName, getIsConnectedName, getCategoryName };
};

export default useEquipmentPropTexts;
