import { isNonEmptyArray } from '@/util/array';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import type { FetchAlarmsOptions } from './api';
import { fetchAlarm, fetchAlarms } from './api';
import type { AlarmData } from './api.types';

export type UseAlarmQueryOptions = Omit<FetchAlarmsOptions, 'equipment_ids'>;

const defaultOptions: UseAlarmQueryOptions = {
  ignore_no_permit: true,
};

export function isEnabled(
  equipmentIds: string[],
  queryOptions: UseQueryOptions<AlarmData>
) {
  const { enabled = true } = queryOptions;
  const hasEquipment = isNonEmptyArray(equipmentIds);

  return hasEquipment && enabled;
}

const useAlarmQuery = (
  equipmentIds: string[],
  options: UseAlarmQueryOptions = {},
  queryOptions: UseQueryOptions<AlarmData> = {}
) => {
  const opts = { ...defaultOptions, ...options, equipment_ids: equipmentIds };
  const enabled = isEnabled(equipmentIds, queryOptions);

  return useQuery({
    queryKey: ['alarms', opts],
    queryFn: () => fetchAlarms(opts),
    ...queryOptions,
    enabled,
  });
};

export default useAlarmQuery;

export const useAlarmByIdQuery = (id: string) =>
  useQuery({
    queryKey: ['alarm', id],
    queryFn: () => fetchAlarm(id),
  });
