import { toQueryParams } from '@/api/util';
import { get, getPaginated } from '@/api/v1';
import { createChunks } from '@/util/array';
import { subSeconds, subWeeks } from 'date-fns';
import { flatten } from 'lodash-es';
import { sampleDataSchema, signalHistogramDataSchema } from './api-schema';
import type {
  BucketOperation,
  DerivedSignalName,
  Iso8601Duration,
  SampleData,
  SampleFilter,
  SignalHistogramData,
  SignalName,
} from './api.types';
import { getBucketOperation } from './bucket';
import { BUCKET_WIDTH_DEFAULT } from './config';
import type { TransformOptions } from './transform';
import transform from './transform';

type Interpolation = 'enabled' | 'disabled';
type Extrapolation = 'start' | 'end' | 'both' | 'disabled';

interface BaseSampleParams {
  signal_names: SignalName[];
  ignore_no_permit?: boolean;
}

export type SampleParams = SampleParamsWithBucket | SampleParamsWithFilter;

export interface SampleParamsWithBucket extends BaseSampleParams {
  start_time?: Date;
  end_time?: Date;
  bucket_width?: Iso8601Duration | null;
  bucket_operation?: BucketOperation[];
  interpolation?: Interpolation;
  extrapolation?: Extrapolation;
  filter?: never;
}

interface SampleParamsWithFilter extends BaseSampleParams {
  start_time?: Date;
  end_time?: Date;
  bucket_width?: never;
  bucket_operation?: never;
  interpolation?: never;
  extrapolation?: never;
  filter?: SampleFilter;
}

export interface SampleParamsStrict {
  signal_names: SignalName[];
  start_time: string;
  end_time: string;
  page_size: number;
  bucket_width?: Iso8601Duration;
  bucket_operation?: BucketOperation;
  filter?: SampleFilter;
  interpolation?: Interpolation;
  extrapolation?: Extrapolation;
  include_null?: boolean;
}

function overfetch(date: Date, transformOptions?: TransformOptions) {
  if (transformOptions?.enable === false) {
    return date;
  }
  return subSeconds(date, transformOptions?.noDataThresholdSeconds ?? 0);
}

export function fetchSamples(
  equipmentIds: string[],
  params: SampleParams,
  transformOptions?: TransformOptions
) {
  const startTime = params.start_time || subWeeks(new Date(), 1);
  const query: SampleParamsStrict = {
    signal_names: params.signal_names,
    start_time: overfetch(startTime, transformOptions).toISOString(),
    end_time: (params.end_time || new Date()).toISOString(),
    page_size: 1000,
  };
  if (params.filter) {
    query.filter = params.filter;
  } else {
    const bucketOperation = getBucketOperation(params.bucket_operation);
    if (bucketOperation !== undefined) {
      query.bucket_operation = bucketOperation;
      query.bucket_width = params.bucket_width || BUCKET_WIDTH_DEFAULT;
    }
  }

  const chunks = createChunks(equipmentIds, 100);

  return Promise.all(
    chunks.map((chunk) => {
      return getPaginated<SampleData>(
        'equipment/sample',
        {
          ...query,
          equipment_ids: chunk,
        },
        {
          schema: sampleDataSchema,
        }
      );
    })
  )
    .then(flatten)
    .then((data) => transform(data, query, transformOptions));
}

export async function fetchSamplesWithInterpolation(
  equipmentIds: string[],
  params: SampleParams
) {
  const query: SampleParamsStrict = {
    signal_names: params.signal_names,
    start_time: (params.start_time || subWeeks(new Date(), 1)).toISOString(),
    end_time: (params.end_time || new Date()).toISOString(),
    page_size: 1000,
    include_null: true,
  };
  if (params.filter) {
    query.filter = params.filter;
  } else {
    const bucketOperation = getBucketOperation(params.bucket_operation);
    if (bucketOperation !== undefined) {
      query.bucket_operation = bucketOperation;
      query.bucket_width = params.bucket_width || BUCKET_WIDTH_DEFAULT;
      query.interpolation = params.interpolation;
      query.extrapolation = params.extrapolation;
    }
  }

  const chunks = createChunks(equipmentIds, 100);

  const samples = await Promise.all(
    chunks.map((chunk) => {
      return getPaginated<SampleData>(
        'equipment/sample',
        {
          ...query,
          equipment_ids: chunk,
        },
        {
          schema: sampleDataSchema,
        }
      );
    })
  );
  return flatten(samples);
}

export interface LatestSampleParams extends BaseSampleParams {
  max_age?: Iso8601Duration;
  bucket_width?: never;
  bucket_operation?: never;
  filter?: never;
}

export const fetchLatestSamples = (
  equipmentIds: string[],
  params: LatestSampleParams
) => {
  const chunks = createChunks(equipmentIds, 100);

  return Promise.all(
    chunks.map((chunk) => {
      const query = toQueryParams({ ...params, equipment_ids: chunk });
      return get<SampleData[]>(`equipment/sample/latest${query}`, {
        defaultValue: [],
        schema: sampleDataSchema,
      });
    })
  ).then((result) => flatten(result));
};

export interface DerivedSamplesParams {
  signal_names: DerivedSignalName[];
  start_time?: Date;
  end_time?: Date;
  ignore_no_permit?: boolean;
}

interface DerivedSamplesParamsStrict {
  signal_names: DerivedSignalName[];
  start_time: string;
  end_time: string;
  ignore_no_permit?: boolean;
}

export const fetchDerivedSamples = (
  equipmentIds: string[],
  params: DerivedSamplesParams
) => {
  const query: DerivedSamplesParamsStrict = {
    signal_names: params.signal_names,
    start_time: (params.start_time || subWeeks(new Date(), 1)).toISOString(),
    end_time: (params.end_time || new Date()).toISOString(),
  };

  const chunks = createChunks(equipmentIds, 100);

  return Promise.all(
    chunks.map((chunk) => {
      return getPaginated<SampleData>(
        'equipment/sample/derived',
        {
          ...query,
          equipment_ids: chunk,
        },
        {
          schema: sampleDataSchema,
        }
      );
    })
  ).then(flatten);
};

export interface SignalHistogramParams {
  signalNames: string[];
  startTime: Date;
  endTime: Date;
  buckets: number[];
}

export const fetchSignalHistogram = (
  equipmentId: string,
  { signalNames, startTime, endTime, buckets }: SignalHistogramParams
) => {
  if (!equipmentId || signalNames.length === 0) {
    return [];
  }
  const queryParams = toQueryParams({
    start_time: startTime.toISOString(),
    end_time: endTime.toISOString(),
    buckets,
  });

  return Promise.all(
    signalNames.map((signalName) =>
      get<SignalHistogramData>(
        `equipment/${equipmentId}/signalname/${signalName}/histogram${queryParams}`,
        { defaultValue: [], schema: signalHistogramDataSchema }
      )
    )
  );
};
