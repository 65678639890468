import { FeatureName } from './index';

export default [
  'admin.hammer-configuration',
  'admin.equipment-new-data-grid',
  'admin.screen-configuration',
  'fleet.alarm-signal-intervals',
  'fleet.claims',
  'fleet.dashboard',
  'fleet.interpolate-samples-server-side',
  'new-data-grid',
] as const;

export const labels: Record<FeatureName, string> = {
  'admin.hammer-configuration': 'Hammer configuration (Ärdy)',
  'admin.equipment-new-data-grid': 'Admin equipment server-side filtering',
  'admin.screen-configuration': 'Screen configuration',
  'fleet.alarm-signal-intervals': 'Alarm thresholds',
  'fleet.claims': 'Warranty claims',
  'fleet.dashboard': 'Equipment dashboard',
  'fleet.interpolate-samples-server-side':
    'Identify gaps in chart data on server',
  'new-data-grid': 'New table',
};
